// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-blog-post-js": () => import("./../../../src/layouts/blog-post.js" /* webpackChunkName: "component---src-layouts-blog-post-js" */),
  "component---src-layouts-mdx-page-js": () => import("./../../../src/layouts/mdx-page.js" /* webpackChunkName: "component---src-layouts-mdx-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-nerd-newsletter-js": () => import("./../../../src/pages/data-nerd-newsletter.js" /* webpackChunkName: "component---src-pages-data-nerd-newsletter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

